/**
 * AdminHeader
 */
export default function AdminHeader() {

    // html代码
    return (
        <nav className="navbar-default navbar-static-side" role="navigation">
            <div className="sidebar-collapse">
                <ul className="nav" id="side-menu">
                    <li className="nav-header">
                        <div className="dropdown profile-element">

                            <a data-toggle="dropdown" className="dropdown-toggle" href="#">
                                <span className="clear">
                                    <span className="block m-t-xs">
                                        <strong className="font-bold">
                                            管理员名字
                                        </strong>
                                    </span>
                                </span>
                            </a>

                        </div>
                        <div className="logo-element">
                            <span >管理员名字</span>
                        </div>
                    </li>

                    <li className="xxxActive">
                        <a href="#admin" >
                            <i className="fa fa-home"></i><span className="nav-label">后台首页</span>
                        </a>
                    </li>

                    <li className="xxxActive">
                        <a href="#list" >
                            <i className="fa fa-twitter"></i><span className="nav-label">组织管理</span>
                        </a>
                    </li>

                    <li className="xxxActive">
                        <a href="#admin" >
                            <i className="fa fa-twitter"></i><span className="nav-label">员工管理</span>
                        </a>
                    </li>

                    <li className="xxxActive">
                        <a href="#admin" >
                            <i className="fa fa-flask"></i><span className="nav-label">系统管理</span>
                        </a>
                    </li>

                </ul>
            </div>
        </nav>
    );
}