import AdminHeader from '../LayoutModule/AdminHeader'
import AdminList from './AdminList'
import AdminTop from '../LayoutModule/AdminTop'
import AdminBread from '../LayoutModule/AdminBread'

/**
 * 后台首页-列表
 */
export default function Admin() {
    // 样式
    document.body.setAttribute('class', 'default');

    // html代码
    return (
            <div>
                <AdminHeader />
                <div id="page-wrapper" className="gray-bg dashbard-1">
                    
                    <AdminTop />
                    <AdminBread />

                    <AdminList />
                </div>
            </div>
    );
}