/**
 * AdminTop
 */
export default function AdminTop() {

    // html代码
    return (
        <div className="row border-bottom">
            <nav className="navbar navbar-static-top" role="navigation" >
                <div className="navbar-header">
                    <a className="navbar-minimalize minimalize-styl-2 btn btn-primary" href="#admin"><i className="fa fa-bars"></i>
                    </a>
                </div>

                <ul className="nav navbar-top-links navbar-right">

                    <li className="dropdown" >
                        <a className="dropdown-toggle count-info" data-toggle="dropdown" href="index.html#">
                            <i className="fa fa-bell"></i> <span className="label label-primary">0</span>
                        </a>

                        <ul className="dropdown-menu dropdown-alerts">
                            <li>
                                <a href="mailbox.html">
                                    <div>
                                        <i className="fa fa-envelope fa-fw"></i> 您有16条未读消息
                                        <span className="pull-right text-muted small">4分钟前</span>
                                    </div>
                                </a>
                            </li>
                            <li className="divider"></li>
                            <li>
                                <a href="profile.html">
                                    <div>
                                        <i className="fa fa-qq fa-fw"></i> 3条新回复
                                        <span className="pull-right text-muted small">12分钟钱</span>
                                    </div>
                                </a>
                            </li>
                            <li className="divider"></li>
                            <li>
                                <div className="text-center link-block">
                                    <a href="notifications.html">
                                        <strong>查看所有 </strong>
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <a href="#logout">
                            <i className="fa fa-sign-out"></i>
                            退出
                        </a>
                    </li>

                </ul>

            </nav>
        </div>

    );
}