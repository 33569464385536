

/**
 * 后台列表页的内容区域
 */
export default function AdminList() {

    // html代码
    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="wrapper wrapper-content">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ibox float-e-margins">
                                    <div className="ibox-title ibox-title-autoheight">
                                        <div>
                                            <div className="row padding-left-10">
                                                <a className="btn btn-sm btn-primary"
                                                    href="#Manager/App/edit'">
                                                    新增小程序
                                                </a>
                                                &nbsp;&nbsp;
                                                <a className="btn btn-sm btn-primary"
                                                    href="#Manager/Mainpart">
                                                    小程序主体
                                                </a>

                                                &nbsp;&nbsp;
                                                <a className="btn btn-sm btn-primary"
                                                    href="#Manager/Audit">
                                                    提审记录
                                                </a>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="ibox-content">
                                        <div className="row">

                                        </div>
                                        <div className="table-responsive">

                                            <table className="table table-striped table-bordered table-hover ">
                                                <thead>
                                                    <tr>
                                                        <th className="align-center width180">#</th>
                                                        <th className="align-center width80">小程序</th>
                                                        <th className="align-center width80">AppID</th>
                                                        <th className="align-center width100">建档时间</th>
                                                        <th className="align-center width80">授权状态</th>
                                                        <th className="align-center width180">操作</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td className="align-center">
                                                            <span>1</span>
                                                        </td>


                                                        <td className="align-center">
                                                            名称abc
                                                        </td>

                                                        <td className="align-center">
                                                            xxx99999
                                                        </td>

                                                        <td className="align-center">
                                                            2023-11-18 00:59:10
                                                        </td>

                                                        <td className="align-center">
                                                            状态xxx
                                                        </td>

                                                        <td className="align-center">


                                                            <a href="Manager/App/edit/idxxx"
                                                                className="btn btn-sm btn-primary">
                                                                编辑
                                                            </a>
                                                            &nbsp;



                                                            <a href="#Manager/Open/develop/idxxx"
                                                                className="btn btn-sm btn-primary">
                                                                代码管理
                                                            </a>
                                                            &nbsp;

                                                            <a href="Manager/App/domain/idxxx"
                                                                className="btn btn-sm btn-primary">
                                                                域名
                                                            </a>
                                                            &nbsp;


                                                        </td>
                                                    </tr>




                                                </tbody>
                                            </table>
                                            <div>
                                                <div className="btn-group pull-left">
                                                </div>

                                                <div className="btn-group pull-right">
                                                    翻页链接 1 2 3 4 5 (假的)
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}