

/**
 * 后台首页的内容区域
 */
export default function AdminIndex() {

    // html代码
    return (
        <div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="wrapper wrapper-content">
                            <div className="row">
                                <div className="col-lg-6">

                                    <div className="ibox float-e-margins" hidden>
                                        <div className="ibox-title">
                                            <h5>第三方平台</h5>
                                        </div>
                                        <div className="ibox-content">
                                            <ol>
                                                <li>2020-05-25</li>
                                                <li>通过 微信开放平台 对接 小程序与公众号 用户信息交互 </li>
                                                <li>支持多个小程序授权</li>
                                                <li>支持微信开放平台开发者应用授权</li>
                                                <li>支持公众号:服务号授权</li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div className="ibox float-e-margins">
                                        <div className="ibox-title">
                                            <h5>登录信息</h5>
                                        </div>
                                        <div className="ibox-content">
                                            <p>
                                                当前IP ： 127.0.0.1
                                            </p>
                                            <p>
                                                当前管理员 ：   管理员AAAA
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-6">

                                    <div className="ibox float-e-margins" hidden>
                                        <div className="ibox-title">
                                            <h5>更新日志</h5>
                                        </div>
                                        <div className="ibox-content no-padding">

                                            <div className="panel-body">
                                                <div className="alert alert-warning">

                                                </div>
                                                <div className="panel-group" id="version">


                                                    <div className="panel panel-default">
                                                        <div className="panel-heading">
                                                            <h5 className="panel-title">
                                                                <a data-toggle="collapse" data-parent="#version" href="#v18">
                                                                    v1.3
                                                                </a>
                                                                <code className="pull-right">2018.07.30</code>
                                                            </h5>
                                                        </div>
                                                        <div id="v18" className="panel-collapse collapse in">
                                                            <div className="panel-body">
                                                                <ol>
                                                                    <li>上传txt验证文件</li>
                                                                    <li>优化小程序 页面与自定义配置</li>
                                                                </ol>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="panel panel-default">
                                                        <div className="panel-heading">
                                                            <h5 className="panel-title">
                                                                <a data-toggle="collapse" data-parent="#version" href="#v18">
                                                                    v1.2.1
                                                                </a>
                                                                <code className="pull-right">2018.07.28</code>
                                                            </h5>
                                                        </div>
                                                        <div id="v18" className="panel-collapse collapse in">
                                                            <div className="panel-body">
                                                                <ol>
                                                                    <li>操作日志</li>
                                                                </ol>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="panel panel-default">
                                                        <div className="panel-heading">
                                                            <h5 className="panel-title">
                                                                <a data-toggle="collapse" data-parent="#version" href="#v18">
                                                                    v1.2
                                                                </a>
                                                                <code className="pull-right">2018.07.28</code>
                                                            </h5>
                                                        </div>
                                                        <div id="v18" className="panel-collapse collapse in">
                                                            <div className="panel-body">
                                                                <ol>
                                                                    <li>小程序 授权验证</li>
                                                                    <li>小程序 上传为体验版</li>
                                                                    <li>小程序 提交审核</li>
                                                                    <li>小程序 提交上线</li>
                                                                </ol>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


        </div>
    );
}