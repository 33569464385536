/**
 * test : inbox页面
 */

export default function Inbox() {
    return (
        <div className='main-content-box-wrap'>
            <h2>Inbox</h2>
        </div>
    );
}