/**
 * test : about页面
 */

export default function About() {
    return (
        <div className='main-content-box-wrap'>
            <h3>About</h3>
        </div>
    );
}