/**
 * test : message 页面
 */

export default function Message() {
    return (
        <div className='main-content-box-wrap'>
            <h3>Message</h3>
        </div>
    );
}