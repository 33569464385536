import { Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './Pages/LoginModule/Login.js';
import Admin from './Pages/SystemModule/Admin.js';
import List from './Pages/SystemModule/List.js';

// test
import About from './Pages/TestModule/About.js';
import Inbox from './Pages/TestModule/Inbox.js';
import Message from './Pages/TestModule/Message.js';

function App() {
  return (

    <div id="wrapper">

      {/* <div>
        <h1>Home</h1>
        <ul>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/inbox">Inbox</Link></li>
          <li><Link to="/message">Message</Link></li>
          <li><Link to="/login">Login</Link></li>
        </ul>
      </div> */}

      <div className='main-content-box'>
        <Routes>
          <Route path="/" Component={Login} />
          <Route path="/login" Component={Login} />
          <Route path="/admin" Component={Admin} />
          <Route path="/list" Component={List} />


          <Route path="/inbox" Component={Inbox} />
          <Route path="/message" Component={Message} />
          <Route path="/about" Component={About} />
        </Routes>
      </div>
    </div>

  );
}




export default App;
