/**
 * 登录界面
 */
export default function Login() {
    // 登录界面的样式
    document.body.setAttribute('class', 'gray-bg');

    // html代码
    return (
        <div className="gray-bg">
            <div className="middle-box text-center loginscreen  animated fadeInDown">
                <div>
                    <div>
                        <h1 className="logo-name">绿叶子内务管理</h1>
                    </div>
                    <h3>请先登录~</h3>
                    <div className='login-form-box'>
                        <div className="son-box">
                            <div className="form-group">
                                <input type="email" className="form-control" placeholder="用户名" required="" />
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="密码" required="" />
                            </div>
                            <button type="submit" className="btn btn-primary block full-width m-b">登 录</button>
                            <p className="text-muted text-center"><a href="login.html#" >
                                <small>忘记密码了？</small>
                            </a> | <a href="#admin" >后台(测试)</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}