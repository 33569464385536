/**
 * AdminBread
 */
export default function AdminBread() {

    // html代码
    return (
        <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
                <h2>
                    页面标题
                </h2>

                <div>
                    面包屑导航 / xxx / xxx
                </div>

            </div>
            <div className="col-lg-2">

            </div>
        </div>
    );
}